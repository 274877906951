// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-haku-js": () => import("./../../../src/pages/haku.js" /* webpackChunkName: "component---src-pages-haku-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/articlePage.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-menu-category-page-js": () => import("./../../../src/templates/menuCategoryPage.js" /* webpackChunkName: "component---src-templates-menu-category-page-js" */),
  "component---src-templates-regular-page-js": () => import("./../../../src/templates/regularPage.js" /* webpackChunkName: "component---src-templates-regular-page-js" */),
  "component---src-templates-restaurant-page-js": () => import("./../../../src/templates/restaurantPage.js" /* webpackChunkName: "component---src-templates-restaurant-page-js" */)
}

