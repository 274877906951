/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

require('whatwg-fetch')

exports.onClientEntry = async () => {
  if (!(`IntersectionObserver` in window)) {
    await import(`intersection-observer`)
  }
}

exports.shouldUpdateScroll = ({
  routerProps: { location }
}) => {
  const { state } = location
  
  if(state) {
    if (state.menuLink) {
      return false
    }
  }
}